<div class="drawer-content-screen">
  @if(!cannotLoadSettings && !settingsIsLoading && isUploaded) {
    <div class="drawer-content-screen__title">
      {{ "mailAccountSettings" | translate }}
    </div>
  }

  @if(cannotLoadSettings) {
    <div class="flex-col-center">
      <app-something-went-wrong [showPicture]="false" description="Cannot load settings" (retryEmitter)="ngOnInit()">
      </app-something-went-wrong>
    </div>
  }

  @if(settingsIsLoading) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }

  @if(!settingsIsLoading && !cannotLoadSettings && isUploaded) {
    <div class="drawer-content-screen__body flex flex-col gap-6">
      <div class="flex-col-container-1">
        <div class="drawer-content-screen__sub-title">{{ "emailState" | translate }}</div>
        @if(!settings.configurationCompleted) {
          <div class="flex gap-[0.5rem] items-center text-[12px] mb-1">
            <mat-icon class="text-red-600">warning</mat-icon>
            <mat-error>{{ "notConfigured" | translate }}.</mat-error>
            <div class="text-red-600">{{ "emailAccountIsNotCompleted" | translate }}</div>
          </div>
        }
        <div class="flex flex-col">
          <mat-checkbox
          color="primary"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="settings.active"
            [disabled]="!settings.configurationCompleted"
            >{{ "active" | translate }}
          </mat-checkbox>
        <mat-checkbox
          color="primary"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="settings.download"
            [disabled]="!settings.configurationCompleted"
            >{{ "download" | translate }}
          </mat-checkbox>
        <mat-checkbox
          color="primary"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="settings.send"
            [disabled]="!settings.configurationCompleted"
            >{{ "send" | translate }}
          </mat-checkbox>
        </div>
      </div>
      <form class="flex flex-col gap-6" [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        <div class="flex flex-col gap-2 w-full">
          <div class="drawer-content-screen__sub-title">{{ "downloadSetting" | translate }}</div>
          <div class="flex flex-col">
            <mat-form-field>
              <mat-label>{{ "downloadEach" | translate }}</mat-label>
              <input type="number" matInput id="downloadCron" formControlName="downloadCron" required />
              @if(hasError('downloadCron', 'required')) {
                  <mat-error>{{ "downloadEachIsRequired" | translate }}</mat-error>
              }
              @if(hasError('downloadCron', 'maxlength')) {
                <mat-error>{{ "moreThan50Char" | translate }}</mat-error>
              }
              @if(hasError('downloadCron', 'min')) {
                <mat-error> {{ "greaterThan0" | translate }}</mat-error>
              }
              @if(hasError('downloadCron', 'max')) {
                <mat-error> {{ "lessThan31" | translate }}</mat-error>
              }
              @if(hasError('downloadCron', 'pattern')) {
                 <mat-error>{{ "mustBeInt" | translate }}</mat-error>
              }
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "downloadBatchNumber" | translate }}</mat-label>
              <input type="number" matInput id="downloadBatchNumber" formControlName="downloadBatchNumber" required />
              @if(hasError('downloadBatchNumber', 'min')) {
                <mat-error>{{ "greaterThan0" | translate }}</mat-error>
              }
              @if(hasError('downloadBatchNumber', 'pattern')) {
                <mat-error>{{ "mustBeInt" | translate }}</mat-error>
              }
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ "autoContactSaving" | translate }}</mat-label>
              <mat-select [(value)]="settings.autoContactSavingType">
                @for(source of autoContactSavingSources; track source) {
                  <mat-option [value]="source.id">
                    {{ source.name | translate }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="flex flex-col gap-2">
          <div class="drawer-content-screen__sub-title">{{ "archivingSettingsMailAccountDialog" | translate }}</div>
          <div class="flex flex-col">
            <mat-form-field>
              <mat-label>Code</mat-label>
              <input matInput id="code" formControlName="code" />
              @if(hasError('code', 'required')) {
                <mat-error>Code is required</mat-error>
              }
              @if(hasError('code', 'maxlength')) {
                <mat-error>{{ 'moreThan256Char' | translate }}</mat-error>
              }
            </mat-form-field>
            <mat-checkbox
              color="primary"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="settings.archive"
              (change)="setCodeValidation()"
            >
              {{ "archive" | translate }}
            </mat-checkbox>
            <mat-checkbox
              color="primary"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="settings.archiveNotLinkedNotification"
                >
                {{ "archiveNotLinkedNotification" | translate }}
              </mat-checkbox>
          </div>
        </div>
        @if(isShowAdvancedSettings) {
          <div class="flex flex-col gap-2">
            <div class="drawer-content-screen__sub-title">Sleep</div>
            <mat-form-field>
              <mat-label>Milliseconds</mat-label>
              <input type="number" matInput [(ngModel)]="threadSleepForSend" [ngModelOptions]="{ standalone: true }" />
            </mat-form-field>
          </div>
        }
        @if(isShowPignor) {
          <div class="flex flex-col gap-2">
            <div class="drawer-content-screen__sub-title">{{ "pignor" | translate }}</div>
            <mat-checkbox
              color="primary"
              [(ngModel)]="pignor"
              [ngModelOptions]="{ standalone: true }"
            >{{ "pignor" | translate }}</mat-checkbox>
            @if(isShowAdvancedSettings) {
              <mat-form-field class="w-full">
                <mat-label>Pignor path</mat-label>
                <input matInput [(ngModel)]="settings.pignorPath" formControlName="pignorPath" />
                @if(hasError('pignorPath', 'maxlength')) {
                   <mat-error>{{ 'moreThan256Char' | translate }}</mat-error>
                }
              </mat-form-field>
            }
          </div>
        }
        <div class="flex flex-col gap-2">
          <div class="drawer-content-screen__sub-title">{{ "validationSettingsTitle" | translate }}</div>
          <mat-checkbox
            color="primary"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="settings.validation"
            (change)="checkValidation()"
          >{{ "validation" | translate }}</mat-checkbox>
          <div class="flex flex-col">
            <mat-form-field>
              <mat-label>{{ "validationHours" | translate }}</mat-label>
              <input type="number" matInput id="validationHours" formControlName="validationHours" required />
              @if(hasError('validationHours', 'min')) {
                <mat-error>{{ "greaterThan0" | translate }}</mat-error>
              }
              @if(hasError('validationHours', 'pattern')) {
                <mat-error>{{ "mustBeInt" | translate }}</mat-error>
              }
              @if(hasError('validationHours', 'required')) {
                <mat-error>{{ "isRequired" | translate }}</mat-error>
              }
            </mat-form-field>
            <mat-form-field  style="overflow: hidden">
              <mat-label>{{ "validationStart" | translate }}</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="validateStart" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              @if(hasError('validateStart', 'required')) {
                <mat-error>{{ "isRequired" | translate }}</mat-error>
              }
            </mat-form-field>
          </div>
          <div>
            <mat-checkbox
              color="primary"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="settings.sendNotifyOfValidation">
              {{ "sendNotifyOfValidation" | translate }}
            </mat-checkbox>
            <app-email-tags
              (changeList)="setNotificationEmails($event)"
              style="width: 100%"
              [toList]="notificationEmails"
              [placeHolder]="'emailAddAllert'"
              [isNeedMarginLeft]="false"
              [existedContacts]="[]"></app-email-tags>
              @if(!isAllValid()) {
                <mat-error>{{ "incorrectEmailDispatchingRules" | translate }}</mat-error>
              }
          </div>
        </div>
      </form>
      @if(iscredemISAMSupport) {
        <form [formGroup]="mailAccountSamlClaimsForm" autocomplete="off" novalidate>
          <div class="flex flex-col">
            <div class="drawer-content-screen__sub-title">{{ "mailAccountOidcCredemClaims" | translate }}</div>
            <mat-form-field >
              <mat-label>{{ "superuserRoleClaim" | translate }}</mat-label>
              <input matInput id="superuserRoleClaim" formControlName="superuserRoleClaim" required />
              @if(hasErrorSamlForm('superuserRoleClaim', 'required')) {
                <mat-error>{{ "superuserRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error>
              }
              @if(hasErrorSamlForm('superuserRoleClaim', 'maxlength')) {
                <mat-error>{{ "moreThan265Char" | translate }}</mat-error>
              }
            </mat-form-field>
            <mat-form-field >
              <mat-label>{{ "userRoleClaim" | translate }}</mat-label>
              <input matInput id="userRoleClaim" formControlName="userRoleClaim" required />
              @if(hasErrorSamlForm('userRoleClaim', 'required')) {
                <mat-error>{{ "userRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error>
              }
              @if(hasErrorSamlForm('userRoleClaim', 'maxlength')) {
                <mat-error>{{ "moreThan265Char" | translate }}</mat-error>
              }
            </mat-form-field>
            <mat-form-field >
              <mat-label>{{ "validatorRoleClaim" | translate }}</mat-label>
              <input matInput id="validatorRoleClaim" formControlName="validatorRoleClaim" required />
              @if(hasErrorSamlForm('validatorRoleClaim', 'required')) {
                <mat-error>{{ "validatorRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error>
              }
              @if(hasErrorSamlForm('validatorRoleClaim', 'maxlength')) {
                <mat-error>{{ "moreThan265Char" | translate }}</mat-error>
              }
            </mat-form-field>
            <mat-form-field >
              <mat-label>{{ "creatorRoleClaim" | translate }}</mat-label>
              <input matInput id="creatorRoleClaim" formControlName="creatorRoleClaim" required />
              @if(hasErrorSamlForm('creatorRoleClaim', 'required')) {
                <mat-error >{{ "creatorRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error>
              }
              @if(hasErrorSamlForm('creatorRoleClaim', 'maxlength')) {
                <mat-error>{{ "moreThan265Char" | translate }}</mat-error>
              }
            </mat-form-field>
            <mat-form-field >
              <mat-label>{{ "limitedUserRoleClaim" | translate }}</mat-label>
              <input matInput id="limitedUserRoleClaim" formControlName="limitedUserRoleClaim" required />
              @if(hasErrorSamlForm('limitedUserRoleClaim', 'required')) {
                <mat-error>{{ "limitedUserRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error>
              }
              @if(hasErrorSamlForm('limitedUserRoleClaim', 'maxlength')) {
                <mat-error>{{ "moreThan265Char" | translate }}</mat-error>
              }
            </mat-form-field>
            <mat-form-field >
              <mat-label>{{ "limitedCreatorRoleClaim" | translate }}</mat-label>
              <input matInput id="limitedCreatorRoleClaim" formControlName="limitedCreatorRoleClaim" required />
              @if(hasErrorSamlForm('limitedCreatorRoleClaim', 'required')) {
                <mat-error>{{ "limitedCreatorRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error>
              }
              @if(hasErrorSamlForm('limitedCreatorRoleClaim', 'maxlength')) {
                <mat-error>{{ "moreThan265Char" | translate }}</mat-error>
              }
            </mat-form-field>
          </div>
        </form>
      }
    </div>
    <div class="w-full flex justify-end">
      <button
        mat-flat-button
        color="primary"
        [disabled]="isLoading || !form.valid || !isAllValid() || (iscredemISAMSupport ? !mailAccountSamlClaimsForm.valid : false)"
        (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  }
</div>
