import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TagClient } from '@app-services/api/clients/tag.client';
import { EmailTagClient } from '@app-services/api/clients/email-tag.client';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { TagsComponent } from '../../common/tags/tags.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingButtonComponent } from '../../common/loading-button/loading-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TagContract } from '@app-types/api/tag';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-email-add-tags-dialog',
  templateUrl: './email-add-tags-dialog.component.html',
  styleUrls: ['./email-add-tags-dialog.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    MatDialogModule,
    TagsComponent,
    TranslateModule,
    LoadingButtonComponent,
    MatIconModule,
    MatButtonModule,
    NgxSkeletonLoaderModule,
  ],
})
export class EmailAddTagsDialogComponent implements OnInit {
  public thisTags: string[] = [];
  public existedTags: any[] = [];
  public isLoading: boolean = true;
  public existedTagsResponse: TagContract[] = [];
  public isSave: boolean = false;
  public isSavingTags: boolean = false;
  public tags: string[] = [];
  public isError: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EmailAddTagsDialogComponent>,
    public tagClient: TagClient,
    public dialog: MatDialog,
    public matchError: MatchError,
    private emailTagClient: EmailTagClient,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tags: { tag: string }[];
      emails: {
        tags: { tag: string }[];
      }[];
      mailAccountId: number;
      ids: number[];
    }
  ) {}

  async ngOnInit(): Promise<void> {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.ok();
      }
    });
    await this.loadData();
  }

  async saveTags(): Promise<any> {
    this.isSavingTags = true;
    try {
      await this.emailTagClient.saveTags({
        emailsId: this.data.ids,
        tags: this.thisTags,
      });
      this.ok();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isSavingTags = false;
    }
  }

  public async loadData(): Promise<void> {
    this.isError = false;
    this.isLoading = true;
    try {
      const response = await this.tagClient.getForMailAccount(
        new BaseGetByIdRequest(this.data.mailAccountId)
      );
      this.existedTagsResponse = response.data;

      this.existedTags = this.existedTagsResponse.map(c => c.tag);
      if (this.data.emails.length === 1) {
        const tags: string[] = [];
        this.data.emails[0].tags.forEach(e => {
          tags.push(e.tag);
        });
        this.thisTags = tags;
      }
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  setIsSave(): void {
    this.isSave = true;
  }

  setTags(tags: string[]): void {
    this.tags = tags;
  }

  public close(): void {
    this.dialogRef.close();
  }

  ok(): any {
    if (this.isSave) {
      const tags: { tag: string }[] = [];
      this.tags.forEach(e => tags.push({ tag: e }));
      this.data.tags = tags;
      this.dialogRef.close(this.data);
    } else {
      this.dialogRef.close();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
