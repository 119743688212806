@if(cannotLoadData) {
  <div class="h-full">
    <app-something-went-wrong
      [showPicture]="false"
      (retryEmitter)="ngOnInit()"
    ></app-something-went-wrong>
  </div>
}

@if(isLoading) {
  <app-skeleton-email></app-skeleton-email>
}

@if(!isLoading && !cannotLoadData) {
  <div (click)="setCurrentTagsElement()" class="flex justify-between h-full">
    <div class="drawer-content-screen w-full">
      <div class="w-full pr-2">
        <form class="create-email-form" [formGroup]="form" autocomplete="off" novalidate>
          <div class="w-full flex flex-row flex-wrap content-center justify-between">
            <div class="flex-grow form">
              <div class="flex relative">
                <span class="absolute custom-top">{{ "from" | translate }}</span>
                <div class="subject">{{ senderAddress }}</div>
              </div>
              <div class="flex relative">
                <button
                  mat-icon-button
                  matTooltip="{{ 'openContacts' | translate }}"
                  class="folder-icon"
                  (click)="openContactsDialog()">
                  <mat-icon>folder_shared</mat-icon>
                </button>
                <div [class.show-cc-btn]="!showCc" class="folder-add-cc" (click)="showCc = true">
                  @if(isContactsLoadingTo) {
                    <mat-progress-spinner
                      class="w-[24px]"
                      mode="indeterminate"
                      diameter="16"
                    ></mat-progress-spinner>
                  }
                  @if(!showCc) {
                    <div class="cursor-pointer hover:underline">{{ "addCc" | translate }}</div>
                  }
                </div>
                <div
                  [ngStyle]="{ 'max-height': currentTagsElement === 1 ? '165px' : '60px' }"
                  id="tags-to"
                  class="flex relative tags-main overflow-auto"
                >
                  <span class="absolute custom-top custom-top_tags">{{ "to" | translate }}</span>
                  <app-email-tags
                    class="w-full"
                    [placeHolder]="'to'"
                    [toList]="initToList"
                    [initContacts]="initToContacts"
                    (dynamicSearchValue)="getContactsTo($event)"
                    (changeList)="changeToList($event)"
                    (setCurrentTagsElement)="setCurrentTagsElement(1)"
                    [showCc]="showCc"
                    [existedContacts]="contactsListTo"></app-email-tags>
                </div>
              </div>
            </div>
          </div>
          @if(showCc) {
            <div class="w-full flex flex-row flex-wrap content-center justify-between">
              <div class="flex-grow form">
                <button
                  mat-icon-button
                  matTooltip="{{ 'openContacts' | translate }}"
                  class="folder-icon"
                  (click)="openContactsDialog(true)">
                  <mat-icon>folder_shared</mat-icon>
                </button>
                <div [class.show-cc-btn]="!showCc" class="folder-add-cc" (click)="showCc = true">
                  @if(isContactsLoadingCc) {
                    <mat-progress-spinner
                      class="w-[24px]"
                      mode="indeterminate"
                      diameter="16"
                    ></mat-progress-spinner>
                  }
                </div>
                <div
                  [ngStyle]="{ 'max-height': currentTagsElement === 2 ? '165px' : '60px' }"
                  id="tags-cc"
                  class="flex relative tags-main overflow-auto">
                  <span class="absolute custom-top_tags custom-top"
                >{{ "cc" | translate }}</span>
                  <app-email-tags
                    class="w-full"
                    [placeHolder]="'cc'"
                    (dynamicSearchValue)="getContactsCc($event)"
                    (changeList)="changeCcList($event)"
                    (setCurrentTagsElement)="setCurrentTagsElement(2)"
                    [toList]="initCcList"
                    [initContacts]="initCcContacts"
                    [existedContacts]="contactsListCc"></app-email-tags>
                </div>
              </div>
            </div>
          }
          <div class="flex relative">
            <span class="absolute custom-top">{{ "subject" | translate }}</span>
            <input
              matInput
              class="subject"
              type="text" formControlName="subject"
              (keydown.enter)="$event.preventDefault()"
            />
            @if(hasError('subject', 'maxlength')) {
              <mat-error>You have more than 2048 characters</mat-error>
            }
          </div>
          @if(attachmentFiles.length > 0) {
            <div class="flex relative min-h-[40px] max-h-[200px]">
              <span class="absolute custom-top">{{ "attach" | translate }}</span>
              <div class="subject attachments" type="text">
                <div class="flex flex-wrap">
                  @for(file of attachmentFiles; track file) {
                    <div
                      [matTooltip]="!file.fileId ? ('uploadFileAndScanForVirus' | translate) : null"
                      class="file"
                      [style.cursor]="(file.fileId && !isFileDownloading(file?.fileId)) ? 'pointer' : 'default'"
                    >
                      <span
                        class="leading-1 px-[3px]"
                        (click)="file.fileId && !isFileDownloading(file?.fileId) && getAttachmentFile(file)"
                        [style.cursor]="file.fileId && !isFileDownloading(file?.fileId) ? 'pointer' : 'default'"
                        [style.color]="file.fileId && !isFileDownloading(file?.fileId) ? 'black' : 'grey'"
                        style="overflow-wrap: anywhere"
                      >
                         {{ file.fileName }}
                      </span>
                      <div class="flex justify-end items-center pb-[3px] h-[24px]">
                        @if(isFileDownloading(file?.fileId)) {
                          <mat-progress-spinner
                            class="w-[24px]"
                            mode="indeterminate"
                            diameter="16"
                          ></mat-progress-spinner>
                        }
                        @if(!file.fileId) {
                          <mat-progress-spinner
                            class="w-[24px]"
                            mode="indeterminate"
                            diameter="16"
                          ></mat-progress-spinner>
                        }
                        @if(file.fileId && !isFileDownloading(file?.fileId)) {
                          <mat-icon
                            style="transform: scale(0.65); color: red"
                            (click)="deleteAttachment(file)"
                            mat-icon-button
                          >close</mat-icon>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }

          <div class="flex relative w-full">
            <span class="absolute custom-top custom-top_tags">{{ "tags" | translate }}</span>
            <div
              id="tags-tags"
              [ngStyle]="{ 'max-height': currentTagsElement === 3 ? '165px' : '60px' }"
              class="w-full app-tags tags-main"
            >
              <app-tags
                (setTags)="setTags($event)"
                [emailId]="emailId ? [emailId] : []"
                [existedTags]="existedTags"
                [thisTags]="thisTags"
              ></app-tags>
            </div>
          </div>
        </form>
      </div>
      <div class="drawer-content-screen__body">
        <app-email-body
          [body]="body"
          (valueChangedEventEmitter)="valueChangedEventEmitter($event)"
          class="h-full min-h-[200px]"
        ></app-email-body>
      </div>
      <div class="w-full">
        <div class="flex justify-between overflow-hidden">
          @if(useMobileView) {
            <div class="icon-button-group flex items-center">
              <input
                #uploader
                [hidden]="true"
                type="file"
                multiple
                (change)="addAttachmentFileFromButton($event)"
              />
              @if(canSend) {
                <button
                  mat-icon-button
                  aria-label="Send"
                  matTooltip="{{ 'send' | translate }}"
                  (click)="onSubmit(true)"
                  [disabled]="!validateForm() || !isEveryFileUploaded()"
                >
                  <mat-icon>send</mat-icon>
                </button>
              }
              @if(!canSend) {
                <button
                  mat-icon-button
                  aria-label="Ready to validate"
                  matTooltip="{{ 'readyToValidate' | translate }}"
                  (click)="onSubmit(true)"
                  [disabled]="!validateForm() || !isEveryFileUploaded()"
                >
                  <mat-icon>send</mat-icon>
                </button>
              }

              <button [disabled]="!isEveryFileUploaded()" mat-icon-button matTooltip="{{ 'save' | translate }}" (click)="saveAsDraft()">
                <mat-icon>save_outline</mat-icon>
              </button>
              <button mat-icon-button aria-label="Open templates" matTooltip="{{ 'templates' | translate }}" (click)="showTemplates()">
                <mat-icon>library_books</mat-icon>
              </button>
              <button mat-icon-button aria-label="Open notes" matTooltip="{{ 'openNotes' | translate }}" (click)="showNotes()">
                <mat-icon>note_outline</mat-icon>
              </button>
              <button mat-icon-button aria-label="Attach file(s)" matTooltip="{{ 'attachFile' | translate }}" (click)="uploader.click()">
                <mat-icon>attach_file</mat-icon>
              </button>
              <button mat-icon-button aria-label="Delete" (click)="delete()" matTooltip="{{ 'delete' | translate }}">
                <mat-icon>delete_outline</mat-icon>
              </button>

              <mat-slide-toggle
                [checked]="isSplitEmail"
                (change)="splitEmailToggle()"
                class="ml-2"
                color="primary"
                matTooltip="{{ 'splitSend' | translate }}"
              ></mat-slide-toggle>
            </div>
          }

          @if(!useMobileView) {
            <div class="button-group">
              <input
                #uploader
                [hidden]="true"
                type="file"
                multiple
                (change)="addAttachmentFileFromButton($event)"
              />
              @if(canSend) {
                <button
                  class="page-block-button"
                  color="primary"
                  mat-flat-button
                  (click)="onSubmit(true)"
                  [disabled]="!validateForm() || submitInProgress || saveInProgress || !isEveryFileUploaded()">
                  <app-loading-button text="{{ 'send' | translate }}" [loading]="submitInProgress && !saveInProgress"></app-loading-button>
                </button>
              }
              @if(!canSend) {
                <button
                  class="page-block-button"
                  color="primary"
                  mat-flat-button
                  (click)="onSubmit(true)"
                  [disabled]="!validateForm() || submitInProgress || saveInProgress || !isEveryFileUploaded()"
                >
                  <app-loading-button
                    text="{{ 'readyToApprove' | translate }}"
                    [loading]="submitInProgress && !saveInProgress"
                  ></app-loading-button>
                </button>
              }

              <button
                class="page-block-button"
                color="primary"
                mat-flat-button
                (click)="saveAsDraft()"
                [disabled]="submitInProgress || saveInProgress || !isEveryFileUploaded()">
                <app-loading-button text="{{ 'save' | translate }}" [loading]="saveInProgress"></app-loading-button>
              </button>
              <button
                class="page-block-button"
                color="primary"
                mat-flat-button
                (click)="uploader.click()"
                [disabled]="submitInProgress || saveInProgress"
              >
                  {{ "attachFiles" | translate }}
              </button>
            </div>
          }

          @if(!useMobileView) {
            <div class="button-group">
              <mat-slide-toggle
                [checked]="isSplitEmail"
                (change)="splitEmailToggle()"
                class="mr-3"
                color="primary"
                matTooltip="{{ 'splitSend' | translate }}"
              ></mat-slide-toggle>
              <button mat-icon-button aria-label="Open templates" matTooltip="{{ 'templates' | translate }}" (click)="showTemplates()">
                <mat-icon>library_books</mat-icon>
              </button>
              <button color="primary" matTooltip="{{ 'openNotes' | translate }}" mat-icon-button (click)="showNotes()">
                <mat-icon>note_outline</mat-icon>
              </button>
              <button mat-icon-button aria-label="Delete" (click)="delete()" matTooltip="{{ 'delete' | translate }}">
                <mat-icon>delete_outline</mat-icon>
              </button>
              <button mat-icon-button aria-label="Audit" (click)="audit()" matTooltip="{{ 'audit' | translate }}">
                <mat-icon>verified_outline</mat-icon>
              </button>
              <button
                matTooltip="{{ 'resetAll' | translate }}"
                color="primary"
                mat-icon-button
                (click)="openConfirmDialog('resetSubtitle', 'resetTitle')">
                <mat-icon>restart_alt</mat-icon>
              </button>
            </div>
          }
        </div>
      </div>
    </div>
    <app-email-sidenav
      [menuContent]="menuContent"
      [useMobileView]="useMobileView"
      [emailId]="emailId"
      [mailAccountId]="mailAccountId"
      [menuIsOpened]="menuIsOpened"
      (templateSelected)="templateSelected($event)"
      (refreshNotes)="refreshNotes()"
      (closeMenu)="closeMenu()"
    ></app-email-sidenav>
  </div>
}
