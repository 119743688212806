import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EmailSearchInputComponent } from '../email-search-input/email-search-input.component';
import { EMAIL_QUERY_PARAM, WEB_CODE } from '@app-shared/constants/constants';
import {
  decodeInfoMailAccount,
  encodeInfoMailAccount,
} from '@app-utils/search-params';
import { SearchEvent, SearchInfo } from '@app-types/search';
import { getFolderByType } from '@app-utils/folders';
import { filter } from 'rxjs/operators';
import { MailFolderType } from '@app-types/enums/mail-folder.type';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import { animate, style, transition, trigger } from '@angular/animations';
import { EmailFolderNameComponent } from '../common/email/email-folder-name/email-folder-name.component';
import { SearchTypeEnum } from '@app-types/enums/filters';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { UserFolderContract } from '@app-types/api/mail-folder';
import { TranslateModule } from '@ngx-translate/core';
import {
  MAT_SELECT_CONFIG,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';

@Component({
  selector: 'app-main-header',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    EmailSearchInputComponent,
    EmailFolderNameComponent,
    MatTabsModule,
    TranslateModule,
    MatSelectModule,
  ],
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  animations: [
    trigger('slideDown', [
      transition(':enter', [
        style({ height: '0px', opacity: 0 }),
        animate('300ms ease-out', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ height: '0px', opacity: 0 })),
      ]),
    ]),
  ],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'select-overlay-pane' },
    },
  ],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  @Output() navMenuOpen = new EventEmitter();
  @Output() mailAccountMenuOpen = new EventEmitter();
  public signalRState: boolean; // ??
  public webCode: string;
  public searchValue = '';
  public useMobileView = false;
  private useMobileViewSubscription: Subscription;
  public isDefaultSearchParams = true;

  infoOpenedMailAccount?: SearchInfo;
  public isInbox = true;
  public isAnyFolders = true;
  public isAllFoldersSelected = false;
  public currentFolderName = 'Inbox';
  public currentFolder?: UserFolderContract;
  public selectedTabIndex: number | null;
  public isVisible = false;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private mobileObserverService: MobileObserverService,
    public userWorkspaceService: UserWorkspaceService
  ) {}

  async ngOnInit(): Promise<any> {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));
    this.webCode =
      this.activateRoute.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';
    if (window.location.search) {
      this.setInfoOpenMAilAccount(window.location.href);
    }

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setInfoOpenMAilAccount(window.location.href);
      });
  }

  toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  setInfoOpenMAilAccount(url: string): void {
    const newUrl = new URL(url);
    if (newUrl.pathname.includes('emails')) {
      const search: string = newUrl.searchParams.get(EMAIL_QUERY_PARAM) ?? '';
      if (search) {
        this.infoOpenedMailAccount = decodeInfoMailAccount(search);
        this.isInbox =
          this.infoOpenedMailAccount?.type === MailFolderType.Inbox;
        this.isAnyFolders = !!this.infoOpenedMailAccount?.folder;
        this.selectedTabIndex = this.isInbox
          ? (this.infoOpenedMailAccount?.tab ?? 0)
          : null;
        this.isAllFoldersSelected =
          this.infoOpenedMailAccount?.searchType === SearchTypeEnum.allFolders;
        this.searchValue = this.infoOpenedMailAccount?.search
          ? this.infoOpenedMailAccount.search
          : '';
        this.userWorkspaceService.hideNotifications =
          this.infoOpenedMailAccount?.hideNotifications !== false;
      } else {
        this.infoOpenedMailAccount = undefined;
        this.isInbox = true;
        this.isAnyFolders = true;
        this.isAllFoldersSelected = false;
        this.currentFolderName = 'Inbox';
        this.currentFolder = undefined;
        this.selectedTabIndex = null;
      }
    }
  }

  selectionChange(event: MatSelectChange): void {
    this.changeTab({ index: event.value } as MatTabChangeEvent);
  }

  changeTab(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    const data: SearchInfo = {
      tab: this.selectedTabIndex,
    };

    this.infoOpenedMailAccount?.hideNotifications === false &&
      (data.hideNotifications = this.infoOpenedMailAccount.hideNotifications);
    this.infoOpenedMailAccount?.search &&
      (data.search = this.infoOpenedMailAccount.search);
    this.infoOpenedMailAccount?.searchType &&
      (data.searchType = this.infoOpenedMailAccount.searchType);
    this.infoOpenedMailAccount?.filter &&
      (data.filter = this.infoOpenedMailAccount.filter);

    if (this.infoOpenedMailAccount) {
      const infoMailAcc = encodeInfoMailAccount(
        this.infoOpenedMailAccount.acc as number,
        this.infoOpenedMailAccount.folder as number,
        this.infoOpenedMailAccount.type as MailFolderType,
        data
      );

      this.webCode &&
        this.router.navigate([this.webCode, 'emails'], {
          queryParams: infoMailAcc
            ? {
                [EMAIL_QUERY_PARAM]: infoMailAcc,
              }
            : {},
        });
    }
  }

  public async search($event: SearchEvent): Promise<void> {
    let folder = null;
    if (!this.infoOpenedMailAccount?.acc) {
      folder = getFolderByType(
        this.userWorkspaceService.userFolders,
        this.userWorkspaceService.mailAccounts[0].mailAccountId,
        MailFolderType.Inbox
      );
    }
    const nullableSearchValue =
      this.searchValue?.length === 0 ? '' : this.searchValue;
    const data: SearchInfo = {};
    this.userWorkspaceService.hideNotifications === false &&
      (data.hideNotifications = this.userWorkspaceService.hideNotifications);
    !!nullableSearchValue && (data.search = nullableSearchValue);

    if ($event?.hideNotificationsEmitter) {
      if (this.infoOpenedMailAccount?.filter) {
        data.filter = this.infoOpenedMailAccount.filter;
        this.infoOpenedMailAccount?.searchType &&
          (data.searchType = this.infoOpenedMailAccount.searchType);
      }
    } else {
      $event?.searchType && (data.searchType = $event.searchType);
      $event?.filter && (data.filter = $event.filter);
    }

    (!!this.infoOpenedMailAccount?.tab ||
      this.infoOpenedMailAccount?.tab === 0) &&
      (data.tab = this.infoOpenedMailAccount.tab);

    const infoMailAcc = encodeInfoMailAccount(
      this.infoOpenedMailAccount?.acc ??
        this.userWorkspaceService.mailAccounts[0].mailAccountId,
      this.infoOpenedMailAccount?.folder ?? folder ?? 0,
      this.infoOpenedMailAccount?.type ?? MailFolderType.Inbox,
      data
    );

    this.router.navigate([this.webCode, 'emails'], {
      queryParams: infoMailAcc
        ? {
            [EMAIL_QUERY_PARAM]: infoMailAcc,
          }
        : {},
    });
  }

  public leftMenuClicked(): void {
    this.mailAccountMenuOpen.emit();
  }

  public rightMenuClicked(): void {
    this.navMenuOpen.emit();
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  public onHideNotificationsChanged(hideNotifications: boolean): void {
    this.userWorkspaceService.hideNotifications = hideNotifications;
    const mailAccountIds = this.userWorkspaceService.mailAccounts?.length
      ? this.userWorkspaceService.mailAccounts.map(e => e.mailAccountId)
      : [];
    this.userWorkspaceService.getBadgesForMailAccounts(mailAccountIds);
    this.userWorkspaceService.refreshUnread({
      mailAccountId: (this.infoOpenedMailAccount?.acc ??
        this.userWorkspaceService.mailAccounts[0].mailAccountId) as number,
      updateBadges: false,
    });
  }

  isVisibleFolderName(): boolean {
    return window.location.href.includes('email');
  }

  async ngOnDestroy(): Promise<void> {
    this.useMobileViewSubscription?.unsubscribe();
  }
}
