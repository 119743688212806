import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../error-dialog/error-dialog.component';
import { EmailClient } from '@app-services/api/clients/email-client';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  tap,
} from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { TAG_EMAIL_REGEX } from '@app-shared/constants/regex';

@Component({
  selector: 'app-email-tags',
  templateUrl: './email-tags.component.html',
  styleUrls: ['./email-tags.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    TranslateModule,
  ],
})
export class EmailTagsComponent implements OnInit, AfterViewInit {
  @Input() toList: string[];
  @Input() placeHolder: string;
  @Input() existedContacts: string[];
  @Input() initContacts: string[];
  @Input() showCc: boolean = true;
  @Input() isNeedMarginLeft: boolean = true;
  @Output() public changeList = new EventEmitter();
  @Output() public dynamicSearchValue = new EventEmitter();
  @Output() public setCurrentTagsElement = new EventEmitter();
  public isLoading = false;
  public cannotLoadMeta = false;
  public meta: string;
  public clickedTagValueTo: string | null;
  public form: UntypedFormGroup;
  public classClicked: string;
  public isTagClicked: boolean = false;
  public shiftedTagsTo: string[] = [];
  public focusedTag: string;

  @ViewChild('tagInputSelectTo')
  tagInputSelectTo!: ElementRef<HTMLInputElement>;

  constructor(
    public dialog: MatDialog,
    private emailClient: EmailClient,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      to: new UntypedFormControl(''),
    });
  }

  ngAfterViewInit(): void {
    // server-side search
    if (this.dynamicSearchValue) {
      fromEvent<KeyboardEvent>(this.tagInputSelectTo.nativeElement, 'keyup')
        .pipe(
          filter(
            (e: KeyboardEvent) =>
              e.code !== 'ArrowLeft' &&
              e.code !== 'ArrowRight' &&
              e.code !== 'ArrowDown' &&
              e.code !== 'ArrowUp' &&
              e.code !== 'Enter'
          ),
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => {
            this.dynamicSearchValue.emit(
              this.tagInputSelectTo.nativeElement.value
            );
          })
        )
        .subscribe();
    }
  }

  public onTagToClick($event: any, value: string): void {
    $event.stopPropagation();
    this.isTagClicked = true;
    this.setCurrentTagsElement.emit();
    this.clickedTagValueTo = value;
    const activeEle = document.activeElement;
    (activeEle as HTMLElement)?.focus();
    setTimeout(() => {
      const activeElement = document.activeElement;
      (
        activeElement?.getElementsByClassName('input-label')[0] as HTMLElement
      )?.focus();
      this.isTagClicked = false;
    }, 0);
  }

  openErrorDialog(title: string, description: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      autoFocus: false,
      data: { title, description },
    });
  }

  public filteredToList(): string[] {
    const filteredList = this.existedContacts.filter(
      e => !this.toList.includes(e)
    );
    if (
      this.tagInputSelectTo?.nativeElement?.value &&
      !this.dynamicSearchValue
    ) {
      return filteredList.filter(e =>
        e
          .toLowerCase()
          .includes(this.tagInputSelectTo.nativeElement.value.toLowerCase())
      );
    } else {
      return filteredList;
    }
  }

  public onSubmitTagTo(event: Event): void {
    if (!(event.target as HTMLInputElement).value) {
      this.clickedTagValueTo && this.deleteTagTo(this.clickedTagValueTo);
    } else {
      this.toList = this.toList.map(e =>
        e === this.clickedTagValueTo
          ? (e = (event.target as HTMLInputElement).value)
          : e
      );
      this.toList = this.toList.filter((e, i, arr) => arr.lastIndexOf(e) === i);
      this.clickedTagValueTo = null;
      // if (!this.isTagClicked) {
      //   this.tagInputSelectTo.nativeElement.focus();
      // }
    }
    this.changeList.emit(this.toList);
  }

  @HostListener('window:mousedown', ['$event'])
  keyEvent(e: MouseEvent): void {
    this.classClicked = (e?.target as Element)?.className ?? '';
  }

  onSubmitTagToEnter(event: any): void {
    event.target.blur();
  }

  public selectedTo($event: Event, isFocusOut = false): void {
    const value = ($event?.target as HTMLInputElement)?.value;
    if (isFocusOut && this.classClicked === 'mat-option-text') {
      return;
    }
    if (value) {
      const emails = value
        .split(';')
        .map(e => e.replace(/\s/g, ''))
        .filter(e => e.length);
      const toList = [...this.toList, ...emails];
      if (toList.length >= 100) {
        this.openErrorDialog('error', 'maximumNumberOfRecipients');
      } else {
        this.toList = toList;
        this.toList = this.toList.filter(
          (e, i, arr) => arr.lastIndexOf(e) === i
        );
      }
      this.shiftedTagsTo = [];
      this.tagInputSelectTo.nativeElement.value = '';
      this.changeList.emit(this.toList);
      if (this.initContacts) {
        this.existedContacts = this.initContacts;
      }
    }
  }

  public deleteTagTo(value: string): void {
    this.shiftedTagsTo = [];
    this.toList = this.toList.filter(e => e !== value);
    this.tagInputSelectTo.nativeElement.blur();
    this.changeList.emit(this.toList);
  }

  public isValidTag(value: string): boolean {
    return !!String(value).toLowerCase().match(TAG_EMAIL_REGEX);
  }

  public isTagShiftSelected(value: string): boolean {
    return this.shiftedTagsTo.includes(value);
  }

  public setFocusedTag(value: string): void {
    this.focusedTag = value;
  }

  moveCell(e: any): void {
    if (!this.toList.length) {
      return;
    }
    const activeEle = document.activeElement;
    if (
      e.key === 'ArrowRight' &&
      !this.tagInputSelectTo.nativeElement.value &&
      activeEle &&
      activeEle.parentElement?.tagName === 'DIV'
    ) {
      if (e.shiftKey) {
        this.shiftedTagsTo.push(this.focusedTag);
        (activeEle.nextElementSibling as HTMLElement)?.focus();
        this.shiftedTagsTo.push(this.focusedTag);
        this.shiftedTagsTo = this.shiftedTagsTo.filter(
          (el, i, arr) => arr.indexOf(el) === i
        );
        if (this.focusedTag === this.toList[this.toList.length - 1]) {
          this.focusedTag = '';
        }
      } else {
        this.shiftedTagsTo = [];
        (activeEle.nextElementSibling as HTMLElement)?.focus();
      }
    }

    if (
      e.key === 'ArrowLeft' &&
      (!this.tagInputSelectTo.nativeElement.value ||
        e.target.selectionStart === 0) &&
      activeEle &&
      activeEle.parentElement?.tagName === 'DIV'
    ) {
      if (e.shiftKey) {
        this.shiftedTagsTo.push(this.focusedTag);
        (activeEle.previousElementSibling as HTMLElement)?.focus();
        this.shiftedTagsTo.push(this.focusedTag);
        this.shiftedTagsTo = this.shiftedTagsTo.filter(
          (el, i, arr) => arr.indexOf(el) === i
        );
      } else {
        this.shiftedTagsTo = [];
        (activeEle.previousElementSibling as HTMLElement)?.focus();
      }
    }
    if (e.key === 'End') {
      if (e.shiftKey) {
        this.shiftedTagsTo = this.toList.filter(
          (el, i, arr) => arr.indexOf(this.focusedTag) <= i
        );
        activeEle?.parentNode?.lastChild?.previousSibling?.previousSibling
          ?.previousSibling &&
          (
            activeEle.parentNode.lastChild.previousSibling.previousSibling
              .previousSibling as HTMLElement
          )?.focus();
      } else {
        this.shiftedTagsTo = [];
        activeEle?.parentNode?.lastChild?.previousSibling &&
          (
            activeEle.parentNode.lastChild.previousSibling as HTMLElement
          )?.focus();
      }
    }
    if (e.key === 'Home') {
      if (e.shiftKey) {
        if (!this.focusedTag) {
          this.shiftedTagsTo = this.toList;
        } else {
          this.shiftedTagsTo = this.toList.filter(
            (el, i, arr) => arr.indexOf(this.focusedTag) >= i
          );
        }
        activeEle?.parentNode &&
          (activeEle.parentNode.firstChild as HTMLElement)?.focus();
      } else {
        this.shiftedTagsTo = [];
        activeEle?.parentNode &&
          (activeEle.parentNode.firstChild as HTMLElement)?.focus();
      }
    }
    if (e.key === 'c' && e.ctrlKey) {
      if (this.shiftedTagsTo.length) {
        this.clipboard.copy(
          this.toList.filter(el => this.shiftedTagsTo.includes(el)).join(';')
        );
      } else {
        this.clipboard.copy(this.focusedTag);
      }
    }
    if (e.key === 'a' && e.ctrlKey) {
      this.shiftedTagsTo = this.toList;
    }
    if (
      e.key === 'Backspace' &&
      activeEle?.parentElement?.tagName === 'DIV' &&
      activeEle?.tagName === 'INPUT'
    ) {
      if (!this.tagInputSelectTo.nativeElement.value) {
        this.deleteTagTo(this.toList[this.toList.length - 1]);
        this.tagInputSelectTo.nativeElement.focus();
      }
    }
    this.changeList.emit(this.toList);
  }

  onTagKeyDown(e: { key: string }, value: string): void {
    const activeEle = document.activeElement;
    if (activeEle?.tagName === 'INPUT') {
      return;
    }
    if (e.key === 'Backspace') {
      if (this.shiftedTagsTo.length) {
        this.toList = this.toList.filter(
          el => !this.shiftedTagsTo.includes(el)
        );
        this.tagInputSelectTo.nativeElement.blur();
        this.shiftedTagsTo = [];
      } else {
        this.deleteTagTo(value);
      }
      if (activeEle && activeEle.previousElementSibling) {
        (activeEle.previousElementSibling as HTMLElement)?.focus();
      } else if (activeEle && activeEle.nextElementSibling) {
        (activeEle.nextElementSibling as HTMLElement)?.focus();
      } else {
        this.tagInputSelectTo.nativeElement.focus();
      }
    }
    this.changeList.emit(this.toList);
  }
}
