import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDrawer,
  MatSidenav,
  MatSidenavModule,
} from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { PermissionService } from '@app-services/permission/permission.service';
import { PermissionType } from '@app-types/enums/permission-type';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TranslateModule } from '@ngx-translate/core';
import { MainHeaderComponent } from '../main-header/main-header.component';
import { LoaderComponent } from '../common/loader/loader.component';
import {
  EMAIL_QUERY_PARAM,
  USER_ROLE,
  WEB_CODE,
} from '@app-shared/constants/constants';
import { LocalStorageService } from '@app-services/local-storage.service';
import { Subscription } from 'rxjs';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { delay } from 'rxjs/operators';
import { NavSidenavComponent } from '../nav-sidenav/nav-sidenav.component';
import { EmailsSideNavComponent } from '../emails-sidenav/emails-side-nav.component';
import { MailFolderType } from '@app-types/enums/mail-folder.type';
import { encodeInfoMailAccount } from '@app-utils/search-params';
import { getFolderByType } from '@app-utils/folders';
import { DrawerComponent } from '../common/drawer/drawer.component';
import { DrawerService } from '@app-services/drawer.service';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

const { RTA, ROOT, USER } = USER_ROLE;

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    RouterOutlet,
    MatSidenavModule,
    MainHeaderComponent,
    TranslateModule,
    LoaderComponent,
    NavSidenavComponent,
    EmailsSideNavComponent,
    DrawerComponent,
    SomethingWentWrongComponent,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidenav') public sideNav: MatSidenav;
  @ViewChild(MatDrawer) matDriver: MatDrawer;
  @ViewChild(DrawerComponent)
  set drawer(drawer: DrawerComponent) {
    this.drawerService.setDrawerComponent(drawer);
  }

  public isLoading = false;
  public isLoadingPage = true;
  public isError = false;

  public settingRoute: string | null = null;
  public webCode: string;
  public userRole: string;
  public organizationName: string;
  public isOpenLeftMenu = false;
  public visibleFullScreen = false;

  private useMobileViewSubscription: Subscription;

  constructor(
    public matchError: MatchError,
    private router: Router,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private mobileObserverService: MobileObserverService,
    private observer: BreakpointObserver,
    private drawerService: DrawerService,
    private userWorkspaceService: UserWorkspaceService
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';

    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.isOpenLeftMenu = isMobile));

    await this.loadData();

    const hasPermissionOver = await this.permissionService.hasPermissionOver(
      null,
      null,
      PermissionType.CanEverything
    );

    if (!hasPermissionOver) {
      await this.userWorkspaceService.loadFolders();
      await this.userWorkspaceService.getMailAccounts();
    }

    const lastEndpoints = window.location.pathname.split('/').slice(-2);

    const isEmailUrl = window.location.pathname
      .split('/')
      .filter((e, i, arr) => i === arr.length - 1);

    if (isEmailUrl[0] === this.webCode) {
      if (this.userRole === RTA || this.userRole === ROOT) {
        this.settingRoute && this.router.navigateByUrl(this.settingRoute);
        this.isOpenLeftMenu = false;
      } else {
        this.redirectMail();
      }
    }

    if (lastEndpoints[0] === this.webCode && lastEndpoints[1] === 'emails') {
      if (!window.location.search) {
        this.redirectMail();
      }
      this.isOpenLeftMenu = this.visibleFullScreen;
    } else {
      this.isOpenLeftMenu = false;
    }

    this.webCode === 'admin' && (this.isOpenLeftMenu = false);

    this.isLoadingPage = false;
  }

  redirectMail(): void {
    if (this.userWorkspaceService.mailAccounts?.[0].mailAccountId) {
      const folder = getFolderByType(
        this.userWorkspaceService.userFolders,
        this.userWorkspaceService.mailAccounts[0].mailAccountId,
        MailFolderType.Inbox
      );
      if (!folder) return;
      const infoMailAcc = encodeInfoMailAccount(
        this.userWorkspaceService.mailAccounts[0].mailAccountId,
        folder,
        MailFolderType.Inbox
      );
      this.router.navigate([this.webCode, 'emails'], {
        queryParams: infoMailAcc
          ? {
              [EMAIL_QUERY_PARAM]: infoMailAcc,
            }
          : {},
      });
      !this.isOpenLeftMenu && (this.isOpenLeftMenu = true);
    }
  }

  async getUserOrganizations(): Promise<void> {
    await this.userWorkspaceService.getUserOrganizations(this.webCode);
    if (this.userWorkspaceService.currentOrganization) {
      this.organizationName =
        this.userWorkspaceService.currentOrganization.name;
    } else {
      this.userRole = '';
      this.settingRoute = null;
    }
  }

  public async loadData(): Promise<void> {
    !this.isLoadingPage && (this.isLoading = true);
    this.isError = false;
    try {
      await this.userWorkspaceService.getUserData();
      await this.getUserOrganizations();

      const hasPermissionOver = await this.permissionService.hasPermissionOver(
        null,
        null,
        PermissionType.CanEverything
      );

      if (!this.userWorkspaceService.currentOrganization) {
        if (hasPermissionOver) {
          if (this.webCode !== 'admin') {
            this.router.navigateByUrl(`/${this.webCode}/403`);
          } else {
            this.userRole = ROOT;
            this.settingRoute = `/${this.webCode}/settings/organizations`;
            this.organizationName = '';
            return;
          }
        } else {
          this.router.navigateByUrl(`/${this.webCode}/403`);
        }
      }

      const canManageOrganization =
        await this.permissionService.hasPermissionOver(
          this.userWorkspaceService.currentOrganization?.organizationId ?? null,
          null,
          PermissionType.CanManageOrganization
        );

      if (canManageOrganization) {
        this.userRole = RTA;
        this.settingRoute = `/${this.webCode}/settings/organization/${this.userWorkspaceService.currentOrganization?.organizationId}/general`;
        return;
      }

      const canManageMailAccount =
        await this.permissionService.hasPermissionOverAtLeastOneMailAccount(
          PermissionType.CanManageMailAccount
        );

      if (canManageMailAccount) {
        this.userRole = USER;
        this.settingRoute = `/${this.webCode}/settings/organization/${this.userWorkspaceService.currentOrganization?.organizationId}/mail-accounts`;
        return;
      }

      this.userRole = USER;
      this.settingRoute = null;
    } catch (e: any) {
      if (e.Status === 500) {
        await this.router.navigateByUrl(`/${this.webCode}/500`);
      }
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public onEmailsClick(): void {
    this.redirectMail();
    this.sideNav?.toggle();
    this.matDriver?.open();
  }

  rightMenuOpen(): void {
    !this.visibleFullScreen && this.matDriver?.opened && this.leftMenuOpen();
    this.sideNav?.toggle();
  }

  leftMenuOpen(): void {
    !this.visibleFullScreen && this.sideNav?.opened && this.rightMenuOpen();
    this.matDriver?.toggle();
  }

  ngAfterViewInit(): void {
    this.observer
      .observe(['(max-width: 840px)'])
      .pipe(delay(1))
      .subscribe(res => {
        this.visibleFullScreen = !res.matches;
        res.matches && this.matDriver?.opened && this.matDriver?.toggle();
      });
  }

  async ngOnDestroy(): Promise<any> {
    this.useMobileViewSubscription?.unsubscribe();
  }
}
