import { Injectable } from '@angular/core';
import { UserClient } from './api/clients/user.client';
import { environment } from '@app-environments/environment';
import { UserContract } from '../types/api/user';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './local-storage.service';
import {
  ListOfPossibleLanguages,
  STORAGE_NAMES,
} from '../shared/constants/constants';
import { MailFolderClient } from './api/clients/mail-folder.client';
import { UserMailAccountClient } from './api/clients/user-mail-account.client';
import { UserMailAccountContract } from '../types/api/user-mail-account';
import { OrganizationContract } from '../types/api/organization';
import { UserOrganizationClient } from './api/clients/user-organization.client';
import { ApiRequest } from './api/api.request';
import { MailAccountClient } from './api/clients/mail-account.client';
import { LoggingHandler } from './errors/logging.service';
import { MailAccountGetBadgeResponse } from '../types/api/mail-account';
import {
  MailAccountGetUnreadCountRequest,
  MailFolderGetUnreadCountRequest,
  MailFolderUnreadCount,
  UserFolderContract,
} from '../types/api/mail-folder';
import { SpaceInfo, StorageOrganisation } from '../types/common';
import { SpaceUsedPaymentPlanDetailTypes } from '../shared/constants/space-used';

const { USER_LOCALE, ORGANISATIONS } = STORAGE_NAMES;

@Injectable({
  providedIn: 'root',
})
export class UserWorkspaceService {
  public user: UserContract;
  public userFolders: UserFolderContract[] = [];
  public mailAccounts: UserMailAccountContract[] = [];
  public mailAccountsError = '';
  public userOrganisaions: OrganizationContract[] = [];
  public currentOrganization: OrganizationContract | undefined;
  public spaceInfo: SpaceInfo = {
    maxAvailableSpaceUsed: 0,
    spaceUsed: 0,
    percentage: 0,
    alertPercentage: 90,
  };
  public mailAccountBadges: MailAccountGetBadgeResponse[] = [];
  folderCounts: { [key: string]: MailFolderUnreadCount[] } = {};
  public hideNotifications = true;

  constructor(
    public translate: TranslateService,
    private userClient: UserClient,
    private localStorageService: LocalStorageService,
    private mailFolderClient: MailFolderClient,
    private userMailAccountClient: UserMailAccountClient,
    private userOrganizationClient: UserOrganizationClient,
    private apiRequest: ApiRequest,
    private mailAccountClient: MailAccountClient,
    protected loggingHandler: LoggingHandler
  ) {}

  async getUserData(): Promise<void> {
    const response = await this.userClient.getUser();
    this.user = response.result;
    this.setLanguage(this.user?.localizationCode ?? '');
    this.translate.use(this.localStorageService.getData(USER_LOCALE));
  }

  private setLanguage(code: string): void {
    if (code) {
      this.localStorageService.setData(USER_LOCALE, code);
    } else {
      const elem = navigator.language.split('-')[0];
      if (ListOfPossibleLanguages.includes(elem)) {
        this.localStorageService.setData(USER_LOCALE, elem);
      } else {
        this.localStorageService.setData(USER_LOCALE, environment.language);
      }
    }
  }

  async loadFolders(): Promise<void> {
    try {
      const response = await this.mailFolderClient.getUserFolders();
      this.userFolders = response.data;
    } catch (error) {
      console.log(error);
      this.userFolders = [];
    }
  }

  async getMailAccounts(): Promise<void> {
    try {
      const response = await this.userMailAccountClient.getLoggedUserAccounts();
      this.mailAccounts = response.userMailAccounts;
    } catch (e: any) {
      this.mailAccountsError = `Code:${e.error.Code}, description:${e.error.description}`;
    }
  }

  async getUserOrganizations(webCode: string): Promise<void> {
    this.userOrganisaions = (
      await this.userOrganizationClient.getUserOrganizations()
    ).organizations;
    this.currentOrganization = this.userOrganisaions?.length
      ? this.userOrganisaions.find(e => e.webCode === webCode)
      : undefined;
    if (this.currentOrganization) {
      this.apiRequest.setOrganisationId(
        this.currentOrganization.organizationId
      );
      let currentOrgs: StorageOrganisation[] =
        this.localStorageService.getData(ORGANISATIONS) || [];
      if (!Array.isArray(currentOrgs)) {
        currentOrgs = [];
      }
      currentOrgs = currentOrgs.filter(e => e.webCode !== webCode);
      currentOrgs.unshift({
        name: this.currentOrganization.name,
        webCode: this.currentOrganization.webCode,
      });
      currentOrgs = currentOrgs.slice(0, 3);
      this.localStorageService.setData(ORGANISATIONS, currentOrgs);
      const space = this.currentOrganization.spaceUsedPaymentPlanType
        ? SpaceUsedPaymentPlanDetailTypes[
            this.currentOrganization.spaceUsedPaymentPlanType
          ].value
        : 0;
      this.setSpaceInfo(space, this.currentOrganization?.spaceUsed ?? 0);
      this.spaceInfo.alertPercentage =
        this.currentOrganization.alertLevelSpaceUsed;
    }
  }

  private setSpaceInfo(space: number, spaceUsed: number): void {
    this.spaceInfo.maxAvailableSpaceUsed = space;
    this.spaceInfo.spaceUsed = spaceUsed ? spaceUsed : 0;
    this.spaceInfo.percentage =
      this.spaceInfo.maxAvailableSpaceUsed > 0
        ? +(
            (this.spaceInfo.spaceUsed / this.spaceInfo.maxAvailableSpaceUsed) *
            100
          ).toFixed(2)
        : 0;
  }

  public async getBadgesForMailAccounts(
    mailAccountIds: number[]
  ): Promise<void> {
    if (mailAccountIds.length) {
      const badgesForMailAccount =
        await this.mailAccountClient.getMailAccountsBadges({
          mailAccountIds,
          hideNotification: this.hideNotifications,
        });
      badgesForMailAccount.data.forEach(elem => {
        this.mailAccounts.map(x => {
          if (x.mailAccountId === elem.mailAccountId) {
            x.spaceUsed = elem.spaceUsed ? elem.spaceUsed : 0;
          }
        });
      });

      badgesForMailAccount.data.forEach(e =>
        this.mailAccountBadges.some(x => x.mailAccountId === e.mailAccountId)
          ? (this.mailAccountBadges = this.mailAccountBadges.map(x =>
              x.mailAccountId === e.mailAccountId ? e : x
            ))
          : this.mailAccountBadges.push(e)
      );
      const mailAccountBadgesNeedsToBeRemoved = mailAccountIds.filter(
        e => !badgesForMailAccount.data.some(x => x.mailAccountId === e)
      );
      if (mailAccountBadgesNeedsToBeRemoved.length) {
        this.mailAccountBadges = this.mailAccountBadges.filter(
          e => !mailAccountBadgesNeedsToBeRemoved.includes(e.mailAccountId)
        );
      }
    }
  }

  async refreshUnread({
    mailAccountId,
    updateBadges = true,
  }: {
    mailAccountId: number;
    updateBadges?: boolean;
  }): Promise<void> {
    if (updateBadges) {
      await this.getBadgesForMailAccounts([mailAccountId]);
    }
    const counts = await this.mailFolderClient.getUnreadCount(
      new MailAccountGetUnreadCountRequest(
        mailAccountId,
        this.hideNotifications
      )
    );
    this.folderCounts = { ...this.folderCounts, [mailAccountId]: counts?.data };
  }

  public async refreshUnreadForFolder(
    folderId: number,
    mailAccountId: number,
    updateBadges = true
  ): Promise<any> {
    if (updateBadges && mailAccountId) {
      await this.getBadgesForMailAccounts([mailAccountId]);
    }
    const counts = await this.mailFolderClient.getFolderUnreadCount(
      new MailFolderGetUnreadCountRequest(folderId, this.hideNotifications)
    );
    if (counts === null) {
      this.folderCounts = {
        ...this.folderCounts,
        [mailAccountId]: this.folderCounts[mailAccountId].filter(
          e => e.mailFolderId !== folderId
        ),
      };
    } else {
      const newData = this.folderCounts[mailAccountId].filter(
        e => e.mailFolderId !== counts.mailFolderId
      );
      newData.push(counts);
      this.folderCounts = {
        ...this.folderCounts,
        [mailAccountId]: newData,
      };
    }
  }

  setSettingsMaxSpace(value: number): void {
    this.setSpaceInfo(value, this.spaceInfo.spaceUsed);
  }

  setSettingsAlertLevel(value: number): void {
    this.spaceInfo.alertPercentage = value;
  }
}
