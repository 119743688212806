import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { EMAIL_QUERY_PARAM } from '@app-shared/constants/constants';
import { decodeInfoMailAccount } from '@app-utils/search-params';
import { MailFolderType } from '@app-types/enums/mail-folder.type';
import { SearchTypeEnum } from '@app-types/enums/filters';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import { Subscription } from 'rxjs';
import { UserFolderContract } from '@app-types/api/mail-folder';

@Component({
  selector: 'app-email-folder-name',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './email-folder-name.component.html',
  styleUrls: ['./email-folder-name.component.scss'],
})
export class EmailFolderNameComponent implements OnInit, OnDestroy {
  public isInbox = true;
  public isAnyFolders = true;
  public isAllFoldersSelected = false;
  public currentFolderName = 'Inbox';
  public currentFolder?: UserFolderContract;
  router$: Subscription;

  constructor(
    private router: Router,
    private userWorkspaceService: UserWorkspaceService
  ) {}

  ngOnInit(): void {
    this.setInfoOpenMAilAccount(window.location.href);

    this.router$ = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setInfoOpenMAilAccount(window.location.href);
      });
  }

  setInfoOpenMAilAccount(url: string): void {
    const newUrl = new URL(url);
    const search: string = newUrl.searchParams.get(EMAIL_QUERY_PARAM) ?? '';
    if (search) {
      const infoOpenedMailAccount = decodeInfoMailAccount(search);
      this.isInbox = infoOpenedMailAccount.type === MailFolderType.Inbox;
      this.isAnyFolders = !!infoOpenedMailAccount?.folder;
      this.isAllFoldersSelected =
        infoOpenedMailAccount?.searchType === SearchTypeEnum.allFolders;
      this.currentFolder = this.userWorkspaceService.userFolders.find(
        el =>
          el.mailAccountId === infoOpenedMailAccount.acc &&
          el.mailFolderId === infoOpenedMailAccount.folder
      );
      this.currentFolder?.mailFolderName &&
        (this.currentFolderName = this.currentFolder.mailFolderName);
    } else {
      this.isInbox = true;
      this.isAnyFolders = true;
      this.isAllFoldersSelected = false;
      this.currentFolderName = 'Inbox';
      this.currentFolder = undefined;
    }
  }

  ngOnDestroy(): void {
    this.router$?.unsubscribe();
  }
}
