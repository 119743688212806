import { Component, Input } from '@angular/core';
import { BreadcrumbItem } from '../../../types/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [MatIconModule, RouterLink, TranslateModule],
})
export class BreadcrumbComponent {
  @Input() items: BreadcrumbItem[] = [];

  constructor() {}

  public get sortedItems(): BreadcrumbItem[] {
    return this.items.sort((a, b) => a.index - b.index);
  }

  public isLastItem(index: number): boolean {
    return index === this.items.length - 1;
  }
}
