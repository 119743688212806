@if(hasError) {
  <div class="h-[80%]">
    <app-something-went-wrong
      description="{{ 'cannotLoadMailAccounts' | translate }}"
      [showPicture]="false"
      (retryEmitter)="loadMailAccounts(true)"
    ></app-something-went-wrong>
  </div>
}

@if(!hasError) {
  <div class="flex flex-col items-center">
    <div class="flex w-full items-center gap-2">
      <div class="w-fit">{{ "totalSpaceOccupies" | translate }}</div>
      @if(!isLoading) {
        <div class="blue font-bold">
        {{ spaceUsed }} GB
        </div>
      }
    </div>
    <div class="search-panel">
      <div class="button-group">
        @if(canManageMailAccounts) {
          <button
            mat-raised-button
            color="primary"
            class="w-full text-nowrap"
            aria-label="New mail account"
            (click)="onCreateMailAccountBtnClicked()">
            <mat-icon class="{{useMobileView ? '!m-0' : '' }}">add</mat-icon> {{ useMobileView ? "" : ("newMailAccount" | translate) }}
          </button>
        }
      </div>

      <div class="search-bar !gap-0">
        <app-search-bar
          [fullWidth]="useMobileView"
          (searchEventEmitter)="loadMailAccounts(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"
        ></app-search-bar>
        @if(!useMobileView) {
        <div class="flex justify-end items-center">
          <app-search-order
            (reloadEventEmitter)="reload()"
            (searchOrderChangeEventEmitter)="searchOrderChange($event)"
            (changeOrderEventEmitter)="changeOrder()"
            [searchOrder]="searchOrder"
            [searchOrders]="searchOrders"
            [orderDirection]="filter.orderDirection"
          ></app-search-order>
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)"
          ></mat-paginator>
        </div>
        }
      </div>
    </div>
  </div>
}

@if(useMobileView) {
  <div class="per-page-block flex justify-end ml-[auto]">
    <app-search-order
      (reloadEventEmitter)="reload()"
      (searchOrderChangeEventEmitter)="searchOrderChange($event)"
      (changeOrderEventEmitter)="changeOrder()"
      [searchOrder]="searchOrder"
      [searchOrders]="searchOrders"
      [orderDirection]="filter.orderDirection"
    ></app-search-order>
    <mat-paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="[25, 50, 100]"
      [length]="totalSize"
      [pageIndex]="pageIndex"
      (page)="handlePage($event)">
    </mat-paginator>
  </div>
  @if(!isLoading && !hasError && isUploaded) {
    <div class="mobile-container-cards">
      @for(mailAccount of mailAccounts; track mailAccount) {
          <div class="card">
            <mat-card class="mobile-row">
              <mat-card-content class="cursor-pointer" (click)="redirectToDetails(mailAccount.mailAccountId)">
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "name" | translate }}:</div>
                  <div class="card-table-row-value">{{ mailAccount.name }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "license" | translate }}:</div>
                  <div class="card-table-row-value">{{ mailAccount?.license ?? '' }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "codeSia" | translate }}:</div>
                  <div class="card-table-row-value">{{ mailAccount?.codeSia ?? '' }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "anagId" | translate }}:</div>
                  <div class="card-table-row-value">{{ mailAccount?.anagId ?? '' }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "email" | translate }}:</div>
                  <div class="card-table-row-value">{{ mailAccount.email }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "providerName" | translate }}:</div>
                  <div class="card-table-row-value">{{ mailAccount.providerName }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "emailState" | translate }}:</div>
                  <div class="card-table-row-value">{{ mailAccount?.isActive ? ("active" | translate) : ("notActive" | translate) }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "archive" | translate }}:</div>
                  <div class="card-table-row-value">{{ mailAccount?.archive ? ("active" | translate) : ("notActive" | translate) }}</div>
                </div>
              </mat-card-content>

              <div class="icons-mobile-block">
                @if(!iscredemISAMSupport) {
                  <button
                    mat-icon-button
                    color="primary"
                    [attr.aria-label]="'addMailAccountToUser' | translate"
                    matTooltip="{{ 'addMailAccountToUser' | translate }}"
                    (click)="onAddMailAccountToUsersBtnClicked(mailAccount)">
                    <mat-icon>person_add</mat-icon>
                  </button>
                }
                <a
                  mat-icon-button
                  color="primary"
                  aria-label="Details"
                  [routerLink]="getDetailsLink(mailAccount.mailAccountId)"
                  matTooltip="{{ 'info' | translate }}">
                  <mat-icon>info_outline</mat-icon>
                </a>
              </div>
            </mat-card>
          </div>
      }
    </div>

    @if(mailAccounts.length === 0) {
      <app-no-data></app-no-data>
    }
  }


}

@if(!useMobileView) {
  <div class="table-container">
    @if(!isLoading && !hasError && isUploaded) {
      <table mat-table [dataSource]="dataSource" class="table-row-hover">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
        <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">
          {{ mailAccount.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="license">
        <th
          [class.display-none]="useTabletView"
          mat-header-cell
          *matHeaderCellDef
        >{{ "license" | translate }}</th>
        <td
          [class.display-none]="useTabletView"
          mat-cell
          *matCellDef="let mailAccount"
          (click)="redirectToDetails(mailAccount.mailAccountId)"
        >
            {{ mailAccount.license }}
        </td>
      </ng-container>
      <ng-container matColumnDef="codeSia">
        <th
          [class.display-none]="useTabletView"
          mat-header-cell
          *matHeaderCellDef
        >{{ "codeSia" | translate }}</th>
        <td
          [class.display-none]="useTabletView"
          mat-cell
          *matCellDef="let mailAccount"
          (click)="redirectToDetails(mailAccount.mailAccountId)"
        >
            {{ mailAccount.codeSia }}
        </td>
      </ng-container>
      <ng-container matColumnDef="anagId">
        <th
          [class.display-none]="useTabletView"
          mat-header-cell
          *matHeaderCellDef>{{ "anagId" | translate }}</th>
        <td
          [class.display-none]="useTabletView"
          mat-cell
          *matCellDef="let mailAccount"
          (click)="redirectToDetails(mailAccount.mailAccountId)"
        >
            {{ mailAccount.anagId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ "email" | translate }}</th>
        <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">{{ mailAccount.email }}</td>
      </ng-container>
      <ng-container matColumnDef="provider-name">
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex items-center gap-1">{{ "providerName" | translate }}
            <button mat-icon-button [matMenuTriggerFor]="menuName">
              <mat-icon class="icon" [class.blue]="filter.ProviderId">filter_list</mat-icon>
            </button>
          </div>
        </th>
        <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">
          {{ mailAccount.providerName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="is-active">
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex items-center gap-1">{{ "emailState" | translate }}
            <button mat-icon-button [matMenuTriggerFor]="menuState">
              <mat-icon
                class="icon"
                [class.blue]="filter.isActive!==null || filter.isConfigurationCompleted===false"
              >
                filter_list
              </mat-icon>
            </button>
          </div>
        </th>
        <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">
          <span
            class="flex gap-[0.5rem] items-center {{ mailAccount.configurationCompleted===false ? 'text-red-600' : ''}}"
          >
            {{ mailAccount.configurationCompleted===false ? ("notConfigured" | translate) : mailAccount.isActive ? ("active" | translate) : ("notActive" | translate) }}
            @if(mailAccount.configurationCompleted===false) {
              <mat-icon matTooltip="{{ 'emailAccountIsNotCompleted' | translate }}">
                warning
              </mat-icon>
            }
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="archive">
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex items-center gap-1">{{ "archive" | translate }}
            <button mat-icon-button [matMenuTriggerFor]="menuArchive">
              <mat-icon class="icon" [class.blue]="filter.isArchive!==null">filter_list</mat-icon>
            </button>
          </div>
        </th>
        <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">
          {{ mailAccount.archive ? ("active" | translate) : ("notActive" | translate) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="space">
        <th mat-header-cell *matHeaderCellDef>{{ "spaceUsedGB" | translate }}
        </th>
        <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">
          {{ mailAccount.spaceUsed }}
        </td>
      </ng-container>
      <ng-container matColumnDef="connect">
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex items-center gap-1">{{ "connect" | translate }}
            <button mat-icon-button [matMenuTriggerFor]="menuConnect">
              <mat-icon class="icon" [class.blue]="!!filter.connectionStatus">filter_list</mat-icon>
            </button>
          </div>
        </th>
        <td mat-cell *matCellDef="let mailAccount">
          <span
            class="flex gap-[0.5rem] items-center {{ connectionStatusName[mailAccount.connectionStatus].color }}"
          >
            {{ connectionStatusName[mailAccount.connectionStatus].title | translate }}
            @if(mailAccount.connectionStatusInfo || mailAccount.connectionStatus === 3) {
              <mat-icon
                  matTooltip="{{ mailAccount.connectionStatus === 3
                   ? (connectionStatusName[mailAccount.connectionStatus].info | translate)
                   : (connectionStatusInfo[mailAccount.connectionStatusInfo] | translate) }}"
              >
                warning
              </mat-icon>
            }
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
        <td mat-cell *matCellDef="let mailAccount">
          @if(!iscredemISAMSupport) {
            <button
              mat-icon-button
              color="primary"
              [attr.aria-label]="'addMailAccountToUser' | translate"
              matTooltip="{{ 'addMailAccountToUser' | translate }}"
              (click)="onAddMailAccountToUsersBtnClicked(mailAccount)"
            >
              <mat-icon>person_add</mat-icon>
            </button>
          }
          <a
            mat-icon-button
            aria-label="Details"
            [routerLink]="getDetailsLink(mailAccount.mailAccountId)"
            matTooltip="{{ 'info' | translate }}">
            <mat-icon>info_outline</mat-icon>
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-pointer"></tr>
    </table>
      @if(mailAccounts.length === 0) {
        <app-no-data></app-no-data>
      }
    }
  </div>
}


@if(isLoading) {
  <div class="overflow-hidden flex-1">
    <app-skeleton-table></app-skeleton-table>
  </div>
}

<mat-menu #menuName>
  @for(provider of providers; track provider.name) {
    <button mat-menu-item (click)="searchProviderChange(provider);">
      <span [class.blue]="filter.ProviderId === provider.val">{{ provider.name }}</span>
    </button>
  }
</mat-menu>

<mat-menu #menuState (click)="$event.stopPropagation();">
  @for(status of menuStateList; track status; let indexStatus = $index) {
    <button
      mat-menu-item
      (click)="setFilterChange(status.field, status.value, status.fieldRemove)"
    >
      <span [class.blue]="filter[status.field] === status.value">{{ status.title | translate }}</span>
    </button>
  }
</mat-menu>

<mat-menu #menuArchive (click)="$event.stopPropagation();">
  <button mat-menu-item (click)="setFilterChange('isArchive',true)">
    <span [class.blue]="filter.isArchive">{{"active" | translate}}</span>
  </button>
  <button mat-menu-item (click)="setFilterChange('isArchive',false);">
    <span [class.blue]="filter.isArchive===false">{{"notActive" | translate}}</span>
  </button>
</mat-menu>

<mat-menu #menuConnect (click)="$event.stopPropagation();">
  @for(connectStatus of menuConnectionStatus; track connectStatus; let indexConnect = $index) {
    <button
      mat-menu-item
      (click)="setFilterChange('connectionStatus', indexConnect + 1)"
    >
      <span [class.blue]="filter.connectionStatus === indexConnect + 1">{{ connectStatus.title | translate}}</span>
    </button>
  }

</mat-menu>

<ng-template #createDrawer>
  <app-mail-account-create-dialog
    [organizationId]="organizationId"
    (creationSubmit)="onCreateMailAccount()">
  </app-mail-account-create-dialog>
</ng-template>

<ng-template #addMailAccountsToUsersDrawer>
  <app-add-mail-accounts-to-users-form
    (closeDrawer)="onHideAddMailAccountsToUsersForm()"
    (submitDrawer)="onHideAddMailAccountsToUsersForm()"
    [mailAccounts]="selectedMailAccount ? [selectedMailAccount] : []"
    [organizationID]="organizationId">
  </app-add-mail-accounts-to-users-form>
</ng-template>
