import { DatePipe, Location, NgStyle } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmailClient } from '@app-services/api/clients/email-client';
import { EmailTagClient } from '@app-services/api/clients/email-tag.client';
import { FileClient } from '@app-services/api/clients/file.client';
import { MailFolderClient } from '@app-services/api/clients/mail-folder.client';
import { TagClient } from '@app-services/api/clients/tag.client';
import { TempFileClient } from '@app-services/api/clients/temp-file.client';
import {
  BaseGetByEmailFileIdRequest,
  BaseGetByFileIdRequest,
  BaseGetByIdRequest,
  BaseGetCollectionByIdRequest,
  BasePostCommentRequest,
} from '@app-types/base/base';
import { EmailContract, EmailListItemContract } from '@app-types/api/email';
import { EmailValidationState } from '@app-types/enums/email.validation-state';
import { PermissionService } from '@app-services/permission/permission.service';
import { SnackbarService } from '@app-services/snackbar.service';
import { MailFolderType } from '@app-types/enums/mail-folder.type';
import { EmailAuditClient } from '@app-services/api/clients/email-audit-client';
import { EmailAuditContract } from '@app-types/api/emails/email-audit';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { PermissionType } from '@app-types/enums/permission-type';
import { MailFolderSelectDialogComponent } from '../../mail-account-folders/mail-folder-select-dialog/mail-folder-select-dialog.component';
import sanitizeHtml from 'sanitize-html';
import { EmailState } from 'src/app/types/enums/email-state';
import { AttachmentState } from 'src/app/types/enums/attachment-state';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TagsComponent } from '../../common/tags/tags.component';
import { LoadingIconButtonComponent } from '../../common/loading-icon-button/loading-icon-button.component';
import { EmailNoteListComponent } from '../email-note-list/email-note-list.component';
import { EmailAuditComponent } from '../../common/email/email-audit/email-audit.component';
import { EmailNotificationsDialogComponent } from '../../common/email/email-notifications-dialog/email-notifications-dialog.component';
import { EmailMenuContent } from '@app-types/enums/emails';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmailCreateOrUpdateComponent } from '../email-create-or-update/email-create-or-update.component';
import { EmailBodyComponent } from '../../common/email/email-body/email-body.component';
import { EmailSidenavComponent } from '../../common/email/email-sidenav/email-sidenav.component';
import { MatMenuModule } from '@angular/material/menu';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import { DrawerService } from '@app-services/drawer.service';
import { WEB_CODE } from '@app-shared/constants/constants';
import { Attachment } from '@app-types/api/Attachment';
import { LoadingButtonComponent } from '@app-components/common/loading-button/loading-button.component';
import { SkeletonEmailComponent } from '@app-components/common/skeletons/skeleton-email/skeleton-email.component';

@Component({
  selector: 'app-email-detail-dialog',
  templateUrl: './email-detail-dialog.component.html',
  styleUrls: ['./email-detail-dialog.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    DatePipe,
    MatProgressSpinnerModule,
    TagsComponent,
    LoadingIconButtonComponent,
    EmailNoteListComponent,
    EmailAuditComponent,
    EmailNotificationsDialogComponent,
    CKEditorModule,
    MatTooltipModule,
    EmailCreateOrUpdateComponent,
    EmailBodyComponent,
    EmailSidenavComponent,
    EmailDetailDialogComponent,
    MatMenuModule,
    LoadingButtonComponent,
    NgStyle,
    SkeletonEmailComponent,
  ],
})
export class EmailDetailDialogComponent implements OnInit, OnDestroy {
  @ViewChild('tags') tagsElement: ElementRef;
  @Input() public emailId: number;
  @Input() public mailFolderId: number;
  @Input() public useMobileView = false;
  @Input() currentFolderType: number;
  @Output() public closeDrawer = new EventEmitter();
  @Output() public getInfoAboutOpenedEmail = new EventEmitter<any>();
  @Output() public setSeenOpened = new EventEmitter<any>();
  @Output() public setValidationStateForEmail = new EventEmitter<any>();
  @Output() public deleteFromData = new EventEmitter<any>();

  public isLoading = false;
  public cannotLoadEmailDetails = false;
  public emailStates = EmailState;
  public emailDetails: EmailContract;
  public emailValidationState = EmailValidationState;
  public mailAccountId: number;
  public canSend = false;
  public canValidate = false;
  public existedTags: string[] = [];
  public thisTags: string[] = [];
  public webCode: string;
  public showAdditionalInfo = false;
  public menuIsOpened = false;
  public menuContent: EmailMenuContent | null;
  public routerSubscription: Subscription;
  public createOrUpdateDrawerIsOpen = false;
  public updatedEmailId: number | null;
  public menuTitle: string | null;
  public body: string;
  public approveInProgress = false;
  public rejectInProgress = false;
  public isDownloadingEML = false;
  public isDownloadingSingleEML = false;
  public validatingEmail = false;
  public isAuditLoading = false;
  public isAuditLoadingError = false;
  public isCurrentElementTags = false;
  public isReplying = false;
  public isReplyingAll = false;
  public isForwarding = false;
  public attachmentState = AttachmentState;

  public emailDrawerIsOpen = false;
  public openedEmail: EmailListItemContract | null;
  public events: EmailAuditContract[] | null;
  public displayedColumns: string[] = [
    'eventType',
    'userName',
    'eventDateTime',
    'metadata',
  ];
  public mailFolderType = MailFolderType;
  public downloadingFileIds: number[] = [];

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private snackbarHelper: SnackbarService,
    private emailClient: EmailClient,
    private fileClient: FileClient,
    private tempFileClient: TempFileClient,
    private location: Location,
    public dialog: MatDialog,
    public tagClient: TagClient,
    public matchError: MatchError,
    private permissionService: PermissionService,
    private emailTagClient: EmailTagClient,
    private emailAuditClient: EmailAuditClient,
    private mailFolderClient: MailFolderClient,
    private userWorkspaceService: UserWorkspaceService,
    private drawerService: DrawerService
  ) {
    this.webCode =
      this.activatedRoute.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';
  }

  async ngOnInit(): Promise<void> {
    await this.getEmailDetail(true);
    this.canValidate = await this.permissionService.hasPermissionOver(
      null,
      this.mailAccountId,
      PermissionType.CanValidateEmail
    );
  }

  ngOnDestroy(): void {
    !this.createOrUpdateDrawerIsOpen &&
      !this.emailDrawerIsOpen &&
      this.closeEmailModalForm();
  }

  async setValidated(): Promise<void> {
    this.validatingEmail = true;
    try {
      await this.emailClient.setValidated({
        emailsId: [this.emailId],
        validated: EmailValidationState.Validated,
      });
      if (
        this.emailDetails.validationState === EmailValidationState.NotValidated
      ) {
        this.deleteFromData.emit(this.emailId);
      } else {
        this.setValidationStateForEmail.emit(this.emailId);
      }
      this.emailDetails.validationState = EmailValidationState.Validated;
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.validatingEmail = false;
    }
  }

  // open new detail dialog
  public openEmailDetails(email: EmailListItemContract): void {
    this.openedEmail = email;
    this.emailDrawerIsOpen = true;
  }

  public checkingTheActiveElement(): void {
    const activeElement = document.activeElement;
    this.isCurrentElementTags =
      !!this.tagsElement?.nativeElement?.contains(activeElement);
  }

  public closeEmailModalForm(): void {
    this.closeDrawer.emit();
  }

  async getEmailDetail(isInit: boolean = false): Promise<void> {
    this.isLoading = true;
    this.cannotLoadEmailDetails = false;
    const request = new BaseGetByIdRequest(this.emailId);
    try {
      const result = await this.emailClient.getById(request);
      this.emailDetails = result.email;
      if (this.emailDetails.body) {
        // add htmlRegex to fix bug if not valid html (remove all Html code between <!-- and -->)
        const htmlRegex = new RegExp(/<!--[\s\S]*?-->/g);
        this.body = this.emailDetails.body.replace(htmlRegex, '');
        this.body = sanitizeHtml(this.body, {
          allowedAttributes: false,
          allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat(['data']),
        });
      } else {
        this.body = '';
      }
      this.getInfoAboutOpenedEmail.emit(this.emailDetails);
      const folderResponse = await this.mailFolderClient.get(
        new BaseGetByIdRequest(this.emailDetails.mailFolderId ?? null)
      );
      this.mailAccountId = folderResponse.result.mailAccountId;
      this.currentFolderType = folderResponse.result.folderType;
      let tagsResponse = await this.tagClient.getForMailAccount(
        new BaseGetByIdRequest(this.mailAccountId)
      );

      this.existedTags = tagsResponse.data.map(c => c.tag);

      tagsResponse = await this.emailTagClient.getEmailTags(
        new BaseGetByIdRequest(this.emailId)
      );

      this.thisTags = tagsResponse.data.map(c => c.tag);
      if (isInit && !this.emailDetails.seen) {
        this.setSeen(true, true);
      }
    } catch (e) {
      this.cannotLoadEmailDetails = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  haveAttachments(): boolean {
    if (this.emailDetails.attachmentFiles === undefined) {
      return false;
    }
    return this.emailDetails.attachmentFiles.length > 0;
  }

  async getAttachmentFile(file: Attachment): Promise<void> {
    try {
      file.fileId && this.downloadingFileIds.push(file.fileId);
      if (file.fileId) {
        const request = new BaseGetByEmailFileIdRequest(
          file.fileId,
          this.emailId
        );

        await this.fileClient.download(request);
      }
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.downloadingFileIds = this.downloadingFileIds.filter(
        e => e !== file.fileId
      );
    }
  }

  isFileDownloading(fileId?: number): boolean {
    if (!fileId) {
      return false;
    }
    return this.downloadingFileIds.includes(fileId);
  }

  async reply(): Promise<any> {
    try {
      this.isReplying = true;
      const newEmail = await this.emailClient.reply({ emailId: this.emailId });
      this.openCreateOrUpdateModalForm(newEmail.id, '');
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isReplying = false;
    }
  }

  async replyAll(): Promise<any> {
    try {
      this.isReplyingAll = true;
      const newEmail = await this.emailClient.replyAll({
        emailId: this.emailId,
      });
      this.openCreateOrUpdateModalForm(newEmail.id, '');
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isReplyingAll = false;
    }
  }

  async forward(): Promise<any> {
    try {
      this.isForwarding = true;
      const newEmail = await this.emailClient.forward({
        emailId: this.emailId,
      });
      this.openCreateOrUpdateModalForm(newEmail.id, '');
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isForwarding = false;
    }
  }

  async delete(): Promise<any> {
    try {
      await this.emailClient.delete({ emailIds: [this.emailId] });
      this.emailDetails.deleted = !this.emailDetails.deleted;
      this.drawerService.closeDrawer();
      this.closeDrawer.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async restoreFromTrash(): Promise<any> {
    const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
      width: '350px',
      autoFocus: false,
      data: {
        mailAccountId: this.mailAccountId,
        ids: this.emailId,
        isRestore: true,
        folderType: this.currentFolderType,
        isTrash: true,
        sourceFoldersType: this.emailDetails.sourceFolderType,
        title: 'Select the folder to restore',
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (e: { confirmed: boolean; folderId: number }) => {
        if (e?.confirmed) {
          try {
            await this.emailClient.restore({
              emailIds: [this.emailId],
              mailFolderId: e.folderId,
            });
            this.emailDetails.deleted = !this.emailDetails.deleted;
            this.closeDrawer.emit();
          } catch (e) {
            this.matchError.errorHandler(e);
            this.matchError.logError(e);
          }
        }
      });
  }

  async restoreMoveFolderChoose(
    ids: number[],
    operationType: string
  ): Promise<any> {
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
        width: '350px',
        autoFocus: false,
        data: {
          mailAccountId: this.mailAccountId,
          ids,
          isRestore: true,
          folderType: this.currentFolderType,
          title: 'Select the folder to restore',
        },
      });
      dialogRef
        .afterClosed()
        .subscribe((e: { confirmed: boolean; folderId: number }) => {
          if (e?.confirmed) {
            if (operationType === 'restoreOneSpam') {
              this.setNotSpam(ids, e.folderId);
            }
          }
        });
    }
  }

  async restore(id: number[], folderId: number): Promise<any> {
    try {
      await this.emailClient.restore({ emailIds: id, mailFolderId: folderId });
      this.emailDetails.deleted = !this.emailDetails.deleted;
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async setNotSpam(id: number[], folderId: number): Promise<any> {
    try {
      await this.emailClient.setNotSpam({
        emailIds: id,
        mailFolderId: folderId,
      });
      this.emailDetails.isSpam = !this.emailDetails.isSpam;
      this.closeDrawer.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async isSpam(): Promise<any> {
    try {
      await this.emailClient.setIsSpam({ emailIds: [this.emailId] });
      this.emailDetails.isSpam = !this.emailDetails.isSpam;
      this.drawerService.closeDrawer();
      this.closeDrawer.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async setSeen(b: boolean, isFirstOpen: boolean = false): Promise<any> {
    try {
      await this.emailClient.setSeen({ emailIds: [this.emailId], seen: b });
      this.setSeenOpened.emit({
        b,
        emailId: this.emailId,
        folderId: this.emailDetails.mailFolderId,
      });
      this.emailDetails.seen = !this.emailDetails.seen;
      if (isFirstOpen) {
        this.emailDetails.seen = true;
      }
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  public showIcon(iconTypes: MailFolderType[]): boolean {
    return iconTypes.includes(this.currentFolderType);
  }

  async approve(): Promise<any> {
    this.approveInProgress = true;
    try {
      await this.emailClient.validationApprove(
        new BaseGetByIdRequest(this.emailId)
      );
      this.approveInProgress = false;
      this.deleteFromData.emit(this.emailId);
      this.userWorkspaceService.refreshUnreadForFolder(
        this.mailFolderId,
        this.mailAccountId
      );
      this.closeDrawer.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.approveInProgress = false;
      this.matchError.logError(e);
    }
  }

  async reject(): Promise<any> {
    this.rejectInProgress = true;
    try {
      await this.emailClient.validationReject(
        new BasePostCommentRequest(this.emailId, '')
      );
      this.rejectInProgress = false;
      this.deleteFromData.emit(this.emailId);
      this.userWorkspaceService.refreshUnreadForFolder(
        this.mailFolderId,
        this.mailAccountId
      );
      this.closeDrawer.emit();
    } catch (e) {
      this.rejectInProgress = false;
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  print(): void {
    window.open(
      `${this.webCode}/mail-account/${this.mailAccountId}/mail-folder/${
        this.emailDetails.mailFolderId
      }/emails-content/${this.emailId}/print?id=${this.userWorkspaceService.currentOrganization?.organizationId ?? ''}`,
      '_blank'
    );
  }

  public showNotifications(): void {
    this.menuContent = EmailMenuContent.Notifications;
    this.menuIsOpened = true;
  }

  async audit(): Promise<void> {
    this.menuContent = EmailMenuContent.Audit;
    this.menuIsOpened = true;
    await this.getEvents();
  }

  async getEvents(): Promise<any> {
    this.isAuditLoadingError = false;
    this.isAuditLoading = true;
    try {
      const response = await this.emailAuditClient.getByEmail(
        new BaseGetCollectionByIdRequest(this.emailId, 1, 100)
      );
      this.events = response.data;
    } catch (e) {
      this.isAuditLoadingError = true;
      this.matchError.logError(e);
    } finally {
      this.isAuditLoading = false;
    }
  }

  public showAttachments(): void {
    this.menuContent = EmailMenuContent.Attachments;
    this.menuIsOpened = true;
  }

  public showNotes(): void {
    this.menuContent = EmailMenuContent.Notes;
    this.menuIsOpened = true;
  }

  public closeMenu(): void {
    this.menuIsOpened = false;
    this.menuContent = null;
  }

  public formatTo(to: string | null): string {
    if (!to) {
      return '';
    }
    return to.split(';').join('; ');
  }

  public getDate(email: EmailContract): string {
    return this.currentFolderType === MailFolderType.Outbox ||
      this.currentFolderType === MailFolderType.Draft ||
      this.currentFolderType === MailFolderType.Validation
      ? email.stateDateTime
      : email.sentDateTime;
  }

  public openCreateOrUpdateModalForm(emailId: number, title: string): void {
    this.updatedEmailId = emailId;
    this.menuTitle = title;
    this.createOrUpdateDrawerIsOpen = true;
  }

  public async downloadSingleEML(): Promise<void> {
    this.isDownloadingSingleEML = true;
    try {
      const fileResponse = await this.emailClient.singleDownload({
        emailIds: [this.emailId],
      });
      const request = new BaseGetByEmailFileIdRequest(
        fileResponse.fileId,
        this.emailId
      );
      await this.fileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
    } finally {
      this.isDownloadingSingleEML = false;
    }
  }

  public async downloadEML(): Promise<void> {
    this.isDownloadingEML = true;
    try {
      const fileResponse = await this.emailClient.download({
        emailIds: [this.emailId],
      });
      const request = new BaseGetByFileIdRequest(fileResponse.fileId);
      await this.tempFileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isDownloadingEML = false;
    }
  }
}
