import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserMailAccountContract } from '@app-types/api/user-mail-account';
import { UserMailAccountClient } from '@app-services/api/clients/user-mail-account.client';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByIdRequest,
  BaseGetSearchCountByIdRequest,
} from '@app-types/base/base';
import { PermissionService } from '@app-services/permission/permission.service';
import { OrganizationClient } from '@app-services/api/clients/organization.client';
import { PermissionNavTabHelper } from '@app-services/permission/permission-nav-tab-helper';
import { RoleType } from '@app-types/enums/role-type';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { OrganizationSettingsClient } from 'src/app/services/api/clients/organization-settings.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SearchBarComponent } from '@app-components/common/search-bar/search-bar.component';
import { NoDataComponent } from '@app-components/common/no-data/no-data.component';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { LocalStorageService } from '@app-services/local-storage.service';
import {
  MAIL_ACCOUNT_ID,
  ORGANIZATION_ID,
  STORAGE_NAMES,
} from '@app-shared/constants/constants';
import { DrawerService } from '@app-services/drawer.service';
import { MailAccountUsersAddComponent } from '@app-components/settings/organization/mail-account/mail-account-users-add/mail-account-users-add.component';
import { DeleteDialogComponent } from '@app-components/common/delete-dialog/delete-dialog.component';
import { Subscription } from 'rxjs';
import { SkeletonTableComponent } from '@app-components/common/skeletons/skeleton-table/skeleton-table.component';

const { PAGE_SIZE_SETTINGS } = STORAGE_NAMES;

@Component({
  selector: 'app-mail-account-users',
  templateUrl: './mail-account-users.component.html',
  styleUrls: ['./mail-account-users.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    SearchBarComponent,
    NoDataComponent,
    SomethingWentWrongComponent,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatCardModule,
    MailAccountUsersAddComponent,
    SkeletonTableComponent,
  ],
})
export class MailAccountUsersComponent implements OnInit, OnDestroy {
  @HostBinding('class') className = 'setting-container';
  public organizationId: number;
  public mailAccountId: number | null;
  public isLoading = false;
  isUploaded = false;
  public iscredemISAMSupport = true;
  public hasError = false;
  public userMailAccounts: UserMailAccountContract[] = [];
  public dataSource = new MatTableDataSource<UserMailAccountContract>(
    this.userMailAccounts
  );
  public displayedColumns: string[] = [
    'first-name',
    'last-name',
    'googleIdentity',
    'oidcIdentity',
    'adfsIdentity',
    'user-email',
    'user-mail-account-role-name',
    'actions',
  ];
  public roleTypes = RoleType;
  public searchValue = '';
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;
  public editingUserMailAccount: UserMailAccountContract | null = null;
  public useMobileView = false;
  public webCode: string;
  private useMobileViewSubscription: Subscription;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('addDrawer') addDrawer: TemplateRef<any>;

  constructor(
    public matchError: MatchError,
    protected router: Router,
    protected route: ActivatedRoute,
    private userMailAccountClient: UserMailAccountClient,
    private permissionService: PermissionService,
    private organizationClient: OrganizationClient,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private mailAccountClient: MailAccountClient,
    public dialog: MatDialog,
    public organizationSettingsClient: OrganizationSettingsClient,
    private mobileObserverService: MobileObserverService,
    private localStorageService: LocalStorageService,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));
    this.pageSize = this.localStorageService.getData(PAGE_SIZE_SETTINGS) || 25;
    this.organizationId = parseInt(
      this.route.snapshot.paramMap.get(ORGANIZATION_ID) as string,
      10
    );
    const mailAccId = this.route.snapshot.paramMap.get(MAIL_ACCOUNT_ID);
    this.mailAccountId = mailAccId ? +mailAccId : null;
    await this.loadMailAccountUsers(true);
  }

  async loadMailAccountUsers(fetchCounter: boolean): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    this.hasError = false;
    if (fetchCounter) {
      this.pageIndex = 0;
    }
    const nullableSearchValue =
      this.searchValue.length > 0 ? this.searchValue : null;
    const request = new BaseCollectionSearchByIdRequest(
      this.mailAccountId,
      this.pageIndex + 1,
      this.pageSize,
      nullableSearchValue
    );
    try {
      const organizationSettings =
        await this.organizationSettingsClient.getByOrganization(
          new BaseGetByIdRequest(this.organizationId)
        );
      this.iscredemISAMSupport = organizationSettings.result.credemISAMSupport;
      const response =
        await this.userMailAccountClient.searchMailAccountUsers(request);
      this.userMailAccounts = response.data.filter(
        e =>
          e?.userEmail &&
          this.permissionService.hasPermissionToSeeUserByUserEmail(e.userEmail)
      );
      this.dataSource.data = this.userMailAccounts;
      if (fetchCounter) {
        const countRequest = new BaseGetSearchCountByIdRequest(
          this.mailAccountId,
          nullableSearchValue
        );
        const counterResponse =
          await this.userMailAccountClient.getSearchMailAccountUsersCount(
            countRequest
          );
        this.totalSize = counterResponse.result;
      }
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isLoading = false;
      this.isUploaded = true;
    }
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  public async handlePage(e: any): Promise<void> {
    this.localStorageService.setData(PAGE_SIZE_SETTINGS, e.pageSize);
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.loadMailAccountUsers(false);
  }

  public onCreateMailAccountUserBtnClicked(): void {
    this.drawerService.openDrawer(this.addDrawer);
  }

  async onAddUserToMailAccount(): Promise<void> {
    this.totalSize += 1;
    await this.loadMailAccountUsers(false);
  }

  public onEditMailAccountUserBtnClicked(
    userMailAccount: UserMailAccountContract
  ): void {
    this.editingUserMailAccount = userMailAccount;
    this.drawerService.openDrawer(this.addDrawer);
  }

  onCloseEditModalForm(): void {
    this.editingUserMailAccount = null;
  }

  async onEditMailAccountUser(): Promise<void> {
    this.editingUserMailAccount = null;
    await this.loadMailAccountUsers(false);
  }

  async onDeleteBtnClicked(userMailAccountId: number): Promise<void> {
    await this.userMailAccountClient.deleteMailAccountUser({
      id: userMailAccountId,
    });
  }

  public deleteUserMailAccount(
    userMailAccountId: number,
    mailAccountName: string,
    userFirstName: string,
    userLastName: string
  ): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        title: 'confirmUserDeletion',
        subTitles: [
          {
            title: 'deleteUser',
            subTitle: userFirstName + ' ' + userLastName,
          },
          {
            title: 'fromCompany',
            subTitle: mailAccountName,
          },
        ],
        onDelete: async () => await this.onDeleteBtnClicked(userMailAccountId),
      },
    });

    dialogRef.afterClosed().subscribe(async x => {
      if (x.isDeleted) {
        this.totalSize -= 1;
        await this.loadMailAccountUsers(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
  }
}
