@if(isLoadingPage) {
  <div class="loader flex items-center justify-center w-full h-full">
    <div class="pr-2 w-[200px] h-[200px] relative">
      <img class="absolute top-0 w-[200px]" src="../../../assets/logo-loader.svg" alt="Logo" />
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '100%',
          'max-width': '200px',
          'width': '200px',
          'background-color': 'transparent',
          'z-index': '100'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
} @else {
  <app-drawer>
    <app-main-header
      (navMenuOpen)="rightMenuOpen()"
      (mailAccountMenuOpen)="leftMenuOpen()"
    ></app-main-header>
    <mat-sidenav-container (backdropClick)="rightMenuOpen()">
      <mat-sidenav #sidenav position="end" [autoFocus]="false">
        @if (isError) {
          <div class="h-full">
            <app-something-went-wrong (retryEmitter)="loadData()"></app-something-went-wrong>
          </div>
        }
        @if (isLoading) {
          <app-loader></app-loader>
        }

        @if(!isLoading && !isError) {
          <ng-container>
            <app-nav-sidenav
              [webCode]="webCode"
              [settingRoute]="settingRoute || ''"
              [userRole]="userRole"
              [organizationName]="organizationName"
              (emailsClick)="onEmailsClick()"
              (rightMenuOpen)="rightMenuOpen()"
            />
          </ng-container>
        }
      </mat-sidenav>

      <mat-sidenav-content>
        <mat-drawer-container
          class="fill"
          [hasBackdrop]="!visibleFullScreen"
        >
          <mat-drawer
            class="fill"
            [autoFocus]="false"
            [mode]="visibleFullScreen ? 'side' : 'over'"
            [opened]="isOpenLeftMenu"
          >
            <app-emails-sidenav (leftMenuOpen)="leftMenuOpen()"/>
          </mat-drawer>
          <mat-drawer-content>
            <div class="w-full primary-container">
              <router-outlet></router-outlet>
            </div>
          </mat-drawer-content>
        </mat-drawer-container>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </app-drawer>
}

