import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app-environments/environment';
import { LoggingContract } from '@app-types/api/logging';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';

const API_URL = environment.apiUrl;
const { create } = API_ROUTES.logging;

@Injectable({
  providedIn: 'root',
})
export class LoggingClient {
  constructor(private http: HttpClient) {}

  async create(request: LoggingContract): Promise<void> {
    this.http.post(`${API_URL}${create}`, request).subscribe(() => {});
  }
}
