import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EmailTagClient } from '@app-services/api/clients/email-tag.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatAutocompleteModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    TranslateModule,
    AsyncPipe,
    MatIconModule,
  ],
})
export class TagsComponent implements OnInit {
  @Input() existedTags: string[];
  @Input() thisTags: string[];
  @Input() emailId: number[];
  @Input() needSaveTags = true;
  @Output() private setIsSave = new EventEmitter();
  @Output() private setTags = new EventEmitter();
  tagCtrl = new UntypedFormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTagsObs: Observable<string[]>;
  public isSavingTags = false;
  public isError = false;
  public errorText: any;
  public newTags = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    public matchError: MatchError,
    private emailTagClient: EmailTagClient
  ) {}

  ngOnInit(): void {
    this.filteredTagsObs = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map(text => {
        if (!text) {
          return this.existedTags.filter(e => !this.thisTags.includes(e));
        } else {
          return this.existedTags.filter(
            e => !this.thisTags.includes(e) && e.toLowerCase().includes(text)
          );
        }
      })
    );
  }

  async saveTags(): Promise<any> {
    this.errorText = '';
    this.isSavingTags = true;
    try {
      const uniqueTags = this.thisTags.filter(
        (e, i, arr) => arr.indexOf(e) === i
      );

      if (uniqueTags.length !== this.thisTags.length) {
        this.thisTags = uniqueTags;
        throw new Error();
      }
      if (this.needSaveTags) {
        await this.emailTagClient.saveTags({
          emailsId: this.emailId,
          tags: this.thisTags,
        });
      }
      this.setIsSave.emit();
    } catch (e) {
      this.errorText = 'Something went wrong';
      this.matchError.logError(e);
    } finally {
      this.setTags.emit(this.thisTags);
      this.isSavingTags = false;
    }
  }

  removeTag(tag: string): void {
    const index = this.thisTags.indexOf(tag);
    if (index >= 0) {
      this.thisTags.splice(index, 1);
    }
    this.tagCtrl.setValue('');
    this.saveTags();
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    this.thisTags.push(event.option.value);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue('');
    this.saveTags();
  }
}
