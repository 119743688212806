<mat-form-field class="tags-chip-list" >
  <mat-chip-grid #chipList aria-label="Tags selection" class="flex-wrap">
    @for(tag of thisTags; track tag) {
      <mat-chip-row
        [disableRipple]="true"
        [removable]="true"
        (removed)="removeTag(tag)"
      >
        {{ tag }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
    }
    @if(isSavingTags) {
      <span [class.spinner]="isSavingTags"></span>
    }
    <input
      placeholder="{{'newTag' | translate}}"
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
    @for(tag of filteredTagsObs | async; track tag) {
      <mat-option [value]="tag">
        {{ tag }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
